import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const ResetPassword = ({ mobileNumber }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    otp: "",
    newPassword: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = await axios.post("/auth/reset-password", {
      ...formData,
      mobileNumber,
    });
    if (res) {
      navigate("/login");
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <div class="form-row">
        <div class="input-data">
          <input
            type="number"
            name="otp"
            value={formData.otp}
            onChange={handleChange}
            required
            min={0}
          />
          <div class="underline"></div>
          <label for="">OTP</label>
        </div>
      </div>

      <div class="form-row">
        <div class="input-data">
          <input
            type="password"
            name="newPassword"
            value={formData.newPassword}
            onChange={handleChange}
            required
            min={0}
          />
          <div class="underline"></div>
          <label for="">New Password</label>
        </div>
      </div>

      <button
        type="submit"
        class="btn text-light bg-blue rounded-0 ms-4 px-4 py-2 my-3"
      >
        Reset Password
      </button>
    </form>
  );
};

export default ResetPassword;
