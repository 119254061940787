import React, { useState } from "react";
import Dropdown from "../components/Dropdown";
import Sidebar from "../components/Sidebar";
import axios from "axios";
import { HiOutlineBars4 } from "react-icons/hi2";
import { Link } from "react-router-dom";

const AddBalance = () => {
  const [showBML, setShowBML] = useState(true);
  const [amount, setAmount] = useState("");
  const [file, setFile] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setShowBML(!showBML);
  };

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const copyToClipboard = (textToCopy) => {
    navigator.clipboard.writeText(textToCopy);
    alert("Copied!");
  };

  const addBalance = () => {
    const fd = new FormData();
    fd.append("file", file);
    fd.append("amount", amount);
    fd.append("type", showBML ? "BML" : "MIB");
    axios.post("/requests/balance", fd).then((res) => {
      if (res) {
        setAmount("");
      }
    });
  };

  return (
    <div className="d-flex bg-light">
      {isOpen && <Sidebar />}
      <div className="rightSide">
        <div className="bg-Banner">
          <div className="d-flex justify-content-between mx-5 align-items-center py-3">
            <div className="d-flex align-items-center gap-2">
              <HiOutlineBars4
                size={23}
                onClick={toggleSidebar}
                className="text-light cursor-pointer"
              />
              <h6 className="text-light mb-0">VAGUTHUN RECHARGE</h6>
            </div>{" "}
            <Dropdown />
          </div>
          <div className="mt-5">
            <h1 className="fw-bold text-center text-light">Add Balance</h1>
            <p className="fs-5 mb-0 text-center text-light">
              Make transfer only to the following accounts
            </p>
          </div>
        </div>

        <div className="mx-3 my-4 bg-white shadow p-3">
          <div className="d-flex gap-2">
            <p
              className={`mb-0 bg-light p-2 cursor-pointer ${
                showBML ? "active" : ""
              }`}
              onClick={handleToggle}
            >
              BML
            </p>
            <p
              className={`mb-0 bg-light p-2 cursor-pointer ${
                !showBML ? "active" : ""
              }`}
              onClick={handleToggle}
            >
              MIB
            </p>
          </div>

          {showBML ? (
            <div className="bml">
              <div className="d-flex justify-content-between mt-3">
                <p className="mb-0">BML Account Details</p>{" "}
                <Link
                  to="https://kyc.vaguthun.com"
                  className="fs-14 text-decoration-none"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa-solid fa-wrench"></i>
                </Link>
              </div>
              <hr />
              <div className="d-flex justify-content-between">
                <p className="mb-0 fw-bold">MVR Account</p>
                <div>
                  <p
                    className="text-green mb-0 copy_text cursor-pointer"
                    title="Click to copy"
                    onClick={() => copyToClipboard("7730000178046")}
                  >
                    7730000178046
                  </p>{" "}
                  <p className="mb-0">FR-23</p>
                </div>
              </div>
              <hr />
              <div className="d-flex justify-content-between">
                <p className="mb-0 fw-bold">USD Account</p>
                <div>
                  <p
                    className="text-green mb-0 copy_text cursor-pointer"
                    title="Click to copy"
                    onClick={() => copyToClipboard("7730000178046")}
                  >
                    7730000178046
                  </p>
                  <p className="mb-0">FR-USD</p>
                </div>
              </div>
              <hr />
              <div className="d-flex justify-content-between">
                <p className="mb-0 fw-bold">My Account</p>
                <p className="mb-0">Account Name</p>
              </div>
            </div>
          ) : (
            <div className="mib">
              <div className="d-flex justify-content-between mt-3">
                <p className="mb-0">MIB Account Details</p>
                <i className="fa-solid fa-wrench"></i>
              </div>
              <hr />
              <div className="d-flex justify-content-between">
                <p className="mb-0 fw-bold">MVR Account</p>
                <p className="text-green copy_text cursor-pointer mb-0">
                  7730000178046
                </p>
              </div>
              <hr />
              <div className="d-flex justify-content-between">
                <p className="mb-0 fw-bold">USD Account</p>
                <p className="mb-0"></p>
              </div>
              <hr />
              <div className="d-flex justify-content-between">
                <p className="mb-0 fw-bold">My Account</p>
                <p className="mb-0">Account Name</p>
              </div>
            </div>
          )}
        </div>
        <div className="mx-3 my-4 bg-white shadow p-3">
          <h5>Add Balance</h5>
          <hr />
          <div className="row">
            <div className="col-md-4">
              <p className="mb-0 fs-14">
                To add balance to your account, please transfer to our bank
                account shown above and enter the amount you have transferred.
              </p>
            </div>
            <div className="col-md-8">
              <label htmlFor="amount">Amount</label>
              <div className="input-group mb-3 ">
                <span className="input-group-text" id="basic-addon1">
                  <i className="fa-regular fa-credit-card"></i>
                </span>
                <input
                  type="number"
                  id="amount"
                  className="form-control"
                  placeholder="Amount You have transferred"
                  aria-label="Amount You have transferred"
                  aria-describedby="basic-addon1"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </div>
              <label htmlFor="bmlReceipt">BML Receipt</label>
              <input
                type="file"
                onChange={(e) => setFile(e.target.files[0])}
                id="bmlReceipt"
                className="form-control"
              />
              <button
                onClick={addBalance}
                className="btn bg-blue text-light mt-3"
              >
                REQUEST
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddBalance;
