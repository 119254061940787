import React from "react";
import { Link } from "react-router-dom";
import cookie from "../utils/cookies";
import axios from "axios";
import { IoHomeOutline, IoWalletOutline } from "react-icons/io5";
import { HiOutlineBolt } from "react-icons/hi2";
import { LiaFilmSolid } from "react-icons/lia";
import { GoClock } from "react-icons/go";
import { SlSocialDropbox } from "react-icons/sl";
import { IoStatsChartOutline } from "react-icons/io5";
import { RiLogoutBoxLine } from "react-icons/ri";
import { SlUser } from "react-icons/sl";

const Sidebar = () => {
  const user = cookie.get("auth");
  const logout = async () => {
    await axios.post("/auth/logout", {
      refreshToken: user.tokens.refresh.token,
    });
    cookie.remove("auth");
    window.location.href = "/";
  };

  return (
    <>
      <div class="bg-blue">
        <div class="sidebar">
          <div class="position-fixed left-0">
            <div class="flex-column menu">
              <div
                class="d-flex flex-column align-items-center gap-4 py-3 mt-4 mb-3"
                style={{ backgroundColor: "rgba(0, 0, 0, 0.1)" }}
              >
                <SlUser size={60} />

                <p class="mb-0">{user.user.mobileNumber}</p>
              </div>
              <div class="py-2 d-flex gap-2 ps-3 align-items-center cursor-pointer">
                <IoHomeOutline />

                <Link
                  to={"/dashboard"}
                  class="fs-14 text-light text-decoration-none"
                >
                  Home
                </Link>
              </div>
              <div class="py-2 d-flex gap-2 ps-3 align-items-center cursor-pointer">
                <IoWalletOutline />

                <Link
                  to={"/addbalance"}
                  class="fs-14 text-light text-decoration-none"
                >
                  Add Balance
                </Link>
              </div>
              <div class="py-2 d-flex gap-2 ps-3 align-items-center cursor-pointer">
                <HiOutlineBolt />
                <Link
                  to={"/recharge"}
                  class="fs-14 text-light text-decoration-none"
                >
                  Recharge and Billpays
                </Link>
              </div>
              <div class="py-2 d-flex gap-2 ps-3 align-items-center cursor-pointer">
                <LiaFilmSolid />

                <Link
                  to={"/medianet"}
                  class="fs-14 text-light text-decoration-none"
                >
                  Medianet
                </Link>
              </div>
              <div class="py-2 d-flex gap-2 ps-3 align-items-center cursor-pointer">
                <GoClock />
                <Link
                  to={"/schedule"}
                  class="fs-14 text-light text-decoration-none"
                >
                  Schedule
                </Link>
              </div>
              <div class="py-2 d-flex gap-2 ps-3 align-items-center cursor-pointer">
                <SlSocialDropbox />
                <Link
                  to={"/giftcards"}
                  class="fs-14 text-light text-decoration-none"
                >
                  Gift Cards
                </Link>
              </div>
              <div class="py-2 d-flex gap-2 ps-3 align-items-center cursor-pointer">
                <SlSocialDropbox />{" "}
                <Link
                  to="https://kyc.vaguthun.com"
                  className="fs-14 text-light text-decoration-none"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  KYC tab
                </Link>
              </div>
              <div class="py-2 d-flex gap-2 ps-3 align-items-center cursor-pointer">
                <IoStatsChartOutline />
                <Link
                  to={"/history"}
                  class="fs-14 text-light text-decoration-none"
                >
                  HISTORY
                </Link>
              </div>
              <div
                onClick={logout}
                class="py-2 d-flex gap-2 ps-3 align-items-center cursor-pointer"
              >
                <RiLogoutBoxLine />

                <a class="fs-14 text-light text-decoration-none">SIGN OUT</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
