import React from "react";

const Services = () => {
  return (
    <>
      <div style={{ backgroundColor: "#efefef" }} class="py-5">
        <div class="col-md-9 mx-auto">
          <h1 class="text-center fw-bold">
            Our <span class="text-blue">Services</span>
          </h1>
          {/* <!-- <p class="fs-5 text-secondary text-center">
        We strive to be the Maldivian best online Recharging platform.
      </p> --> */}
        </div>
        <hr class="container mb-4" style={{ backgroundColor: "#787777" }} />
        <div class="container">
          <div class="row">
            <div class="col-md-6 mb-4">
              <div class="shadow-blue h-100 p-3">
                <h5>
                  Ooredoo <span class="text-blue">Services</span>
                </h5>
                <p>
                  You can enjoy Ooredoo Raastas, Data/Voice Packages & Vaguthun
                  Broadband Recharge.
                </p>
              </div>
            </div>
            <div class="col-md-6 mb-4">
              <div class="shadow-blue h-100 p-3">
                <h5>
                  Dhiraagu <span class="text-blue">Services</span>
                </h5>
                <p>
                  You can enjoy Dhiraagu Reload, Data/Voice Packages, Dhiraagu
                  Bill Pay, DhiraaguPay Cash-in & Dhiraagu TV/Fiber/ADSL Bill
                  Pay.
                </p>
              </div>
            </div>
            <div class="col-md-6 mb-4">
              <div class="shadow-blue h-100 p-3">
                <h5>
                  Gift <span class="text-blue">Cards</span>
                </h5>
                <p>
                  You can purchase iTunes, Google Play, Netflix, Spotify,
                  PlayStation, Xbox & Steam Gift cards with Vaguthun Recharge.
                </p>
              </div>
            </div>
            <div class="col-md-6 mb-4">
              <div class="shadow-blue h-100 p-3">
                <h5>
                  PUBG <span class="text-blue">Mobile UC</span>
                </h5>
                <p>
                  You can buy PUBG Mobile UC instantly with Vaguthun Recharge.
                </p>
              </div>
            </div>
            {/* <!-- <div class="col-md-6 mb-4">
          <div class="shadow-blue h-100 p-3">
            <h5>Credit/Debit <span class="text-blue">Card Payments</span></h5>
            <p>We accpet Credit/Debit card payments via Stripe.</p>
          </div>
        </div>
        <div class="col-md-6 mb-4">
          <div class="shadow-blue h-100 p-3">
            <h5>Network <span class="text-blue">Detection</span></h5>
            <p>
              Not sure which Operator it is. Just press process. We will take
              care of it. .
            </p>
          </div>
        </div> --> */}
            <div class="col-md-6 mb-4">
              <div class="shadow-blue h-100 p-3">
                <h5>
                  Quality <span class="text-blue">Service</span>
                </h5>
                <p>
                  Quality of service matters, Our uptime is higher than other
                  recharge providers.
                </p>
              </div>
            </div>
            <div class="col-md-6 mb-4">
              <div class="shadow-blue h-100 p-3">
                <h5>
                  24 Hours <span class="text-blue">Serivce</span>
                </h5>
                <p>
                  Enjoy 24/7 Service, Call us at 4003330 if you need assistance
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
