import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import cookie from "../utils/cookies";
import OTP from "../components/OTP";

const Signup = () => {
  const [otpScr, setOtpScr] = useState(false);
  const [formData, setFormData] = useState({
    mobileNumber: "",
    password: "",
    confirmPassword: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.password === formData.confirmPassword) {
      const { mobileNumber } = formData;
      const res = await axios.post("/auth/otpauth", { mobileNumber });
      if (res) {
        setOtpScr(true);
      }
      //   const res = await axios.post("/auth/register", {
      //     mobileNumber,
      //     password,
      //   });
      //   if (res) {
      //     cookie.set("auth", res);
      //     window.location.href = "/dashboard";
      //   }
    } else {
      toast.error("Password does not match");
    }
  };

  return (
    <div className="main_login">
      {otpScr ? (
        <OTP
          mobileNumber={formData.mobileNumber}
          password={formData.password}
        />
      ) : (
        <div className="container-login container">
          <div className="text">Let's Sign Up</div>
          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <div className="input-data">
                <input
                  type="number"
                  name="mobileNumber"
                  value={formData.mobileNumber}
                  onChange={handleChange}
                  required
                />
                <div className="underline"></div>
                <label htmlFor="mobileNumber">Mobile Number</label>
              </div>
            </div>
            <div className="form-row">
              <div className="input-data">
                <input
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                />
                <div className="underline"></div>
                <label htmlFor="password">Password</label>
              </div>
            </div>
            <div className="form-row">
              <div className="input-data">
                <input
                  type="password"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  required
                />
                <div className="underline"></div>
                <label htmlFor="confirmPassword">Confirm Password</label>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <div className="text-blue px-4 d-flex gap-2 align-items-center">
                <i className="fa-solid fa-arrow-right-to-bracket"></i>
                <Link to="/login" className="hoverUnderline">
                  Sign in
                </Link>
              </div>
            </div>
            <button
              type="submit"
              className="btn text-light bg-blue rounded-0 ms-4 px-4 py-2 my-4"
            >
              Submit
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default Signup;
