import React, { useState } from "react";
import Sidebar from "../components/Sidebar";
import Dropdown from "../components/Dropdown";
import RechargeSection from "../components/schedule/RechargeSection";
import DhiraaguSection from "../components/schedule/DhiraaguSection";
import OoredooSection from "../components/schedule/OoredooSection";
import ActiveTasksSection from "../components/schedule/ActiveTasksSection";
import { HiOutlineBars4 } from "react-icons/hi2";

const Schedule = () => {
  const [activeSection, setActiveSection] = useState("Recharge");

  const handleSectionClick = (section) => {
    setActiveSection(section);
  };

  //////////////////////////
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <div class="d-flex bg-light">
        {isOpen && <Sidebar />}
        <div class="rightSide">
          <div class="bg-Banner">
            <div class="d-flex justify-content-between mx-5 align-items-center py-3">
              <div className="d-flex align-items-center gap-2">
                <HiOutlineBars4
                  size={23}
                  onClick={toggleSidebar}
                  className="text-light cursor-pointer"
                />
                <h6 class="text-light mb-0">VAGUTHUN RECHARGE</h6>
              </div>{" "}
              <Dropdown />
            </div>
            <div class="mt-5">
              <h1 class="fw-bold text-center text-light">Schedule Task</h1>
              <p class="fs-5 mb-0 text-center text-light">
                Manage your Payments
              </p>
            </div>
          </div>

          <div class="mx-3 my-4 bg-white shadow p-3">
            <div className="d-flex gap-2 mb-4">
              <p
                className={`mb-0 bg-light p-2 cursor-pointer ${
                  activeSection === "Recharge" ? "active" : ""
                }`}
                onClick={() => handleSectionClick("Recharge")}
              >
                Recharge
              </p>
              <p
                className={`mb-0 bg-light p-2 cursor-pointer ${
                  activeSection === "Dhiraagu" ? "active" : ""
                }`}
                onClick={() => handleSectionClick("Dhiraagu")}
              >
                Dhiraagu
              </p>
              <p
                className={`mb-0 bg-light p-2 cursor-pointer ${
                  activeSection === "Ooredoo" ? "active" : ""
                }`}
                onClick={() => handleSectionClick("Ooredoo")}
              >
                Ooredoo
              </p>
              <p
                className={`mb-0 bg-light p-2 cursor-pointer ${
                  activeSection === "Active Tasks" ? "active" : ""
                }`}
                onClick={() => handleSectionClick("Active Tasks")}
              >
                Active Tasks
              </p>
            </div>
            <div className="section">
              {activeSection === "Recharge" && <RechargeSection />}
              {activeSection === "Dhiraagu" && <DhiraaguSection />}
              {activeSection === "Ooredoo" && <OoredooSection />}
              {activeSection === "Active Tasks" && <ActiveTasksSection />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Schedule;
