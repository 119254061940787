import React from "react";
import { Link } from "react-router-dom";
import cookie from "../utils/cookies";
import axios from "axios";

const Dropdown = () => {
  const user = cookie.get("auth");
  const logout = async () => {
    await axios.post("/auth/logout", {
      refreshToken: user.tokens.refresh.token,
    });
    cookie.remove("auth");
    window.location.href = "/";
  };
  return (
    <>
      <div class="dropdown">
        <button
          class="btn dropdown-toggle shadow-none text-light"
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {user.user.mobileNumber}
        </button>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          <div class="py-2 d-flex gap-1 ps-2 align-items-center cursor-pointer">
            <i class="fa-solid fa-home fs-14"></i>
            <Link to={"/home"} class="fs-13 text-dark text-decoration-none">
              Home
            </Link>
          </div>
          <div class="py-2 d-flex gap-1 ps-2 align-items-center cursor-pointer">
            <i class="fa-solid fa-wallet fs-14"></i>
            <Link
              to={"/addbalance"}
              class="fs-13 text-dark text-decoration-none"
            >
              Add Balance
            </Link>
          </div>
          <div class="py-2 d-flex gap-1 ps-2 align-items-center cursor-pointer">
            <i class="fa-solid fa-bolt fs-14"></i>
            <Link to={"/recharge"} class="fs-13 text-dark text-decoration-none">
              Recharge & Billpays
            </Link>
          </div>
          <div class="py-2 d-flex gap-1 ps-2 align-items-center cursor-pointer">
            <i class="fa-solid fa-film fs-14"></i>
            <Link to={"/medianet"} class="fs-13 text-dark text-decoration-none">
              Medianet
            </Link>
          </div>
          <div class="py-2 d-flex gap-1 ps-2 align-items-center cursor-pointer">
            <i class="fa-regular fa-clock fs-14"></i>
            <Link to={"/schedule"} class="fs-13 text-dark text-decoration-none">
              Schedule
            </Link>
          </div>
          <div class="py-2 d-flex gap-1 ps-2 align-items-center cursor-pointer">
            <i class="fa-brands fa-dropbox fs-14"></i>
            <Link
              to={"/giftcards"}
              class="fs-13 text-dark text-decoration-none"
            >
              Gift Cards
            </Link>
          </div>
          <div class="py-2 d-flex gap-1 ps-2 align-items-center cursor-pointer">
            <i class="fa-solid fa-chart-simple fs-14"></i>
            <Link to={"/history"} class="fs-13 text-dark text-decoration-none">
              HISTORY
            </Link>
          </div>
          <div
            onClick={logout}
            class="py-2 d-flex gap-1 ps-2 align-items-center cursor-pointer"
          >
            <i class="fa-solid fa-arrow-right-from-bracket fs-14"></i>
            <a class="fs-13 text-dark text-decoration-none">SIGN OUT</a>
          </div>
        </ul>
      </div>
    </>
  );
};

export default Dropdown;
