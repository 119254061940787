import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import cookie from "./utils/cookies";
import BASE_URL from "./utils/constants";

const root = ReactDOM.createRoot(document.getElementById("root"));
const auth = cookie.get("auth");
axios.defaults.baseURL = BASE_URL;
axios.defaults.headers.common["Authorization"] =
  "Bearer " + auth?.tokens?.access?.token;

axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (response.data.message) {
      toast.success(response.data.message);
    }
    return response.data;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    toast.error(error?.response?.data?.message);
    // throw error;
  }
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ToastContainer />
      <App />
    </BrowserRouter>
  </React.StrictMode>
);
