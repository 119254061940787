import React, { useState } from "react";
import Dropdown from "../components/Dropdown";
import Sidebar from "../components/Sidebar";
import axios from "axios";
import { HiOutlineBars4 } from "react-icons/hi2";

const MediaNet = () => {
  const [data, setData] = useState({
    number: "",
    id_card: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const sendRequest = () => {
    axios.post("/requests/medianet", data).then((res) => {
      if (res) {
        setData({
          number: "",
          id_card: "",
        });
      }
    });
  };
  
  //////////////////////////
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <div class="d-flex bg-light">
        {isOpen && <Sidebar />}
        <div class="rightSide">
          <div class="bg-Banner">
            <div class="d-flex justify-content-between mx-5 align-items-center py-3">
              <div className="d-flex align-items-center gap-2">
                <HiOutlineBars4
                  size={23}
                  onClick={toggleSidebar}
                  className="text-light cursor-pointer"
                />
                <h6 class="text-light mb-0">VAGUTHUN RECHARGE</h6>
              </div>{" "}
              <Dropdown />
            </div>
            <div class="mt-5">
              <h1 class="fw-bold text-center text-light">Medianet BillPay</h1>
              <p class="fs-5 text-center text-light">Pay your Medianet bills</p>
              <p class="bg-danger col-md-5 mx-auto text-center rounded text-light fs-13">
                Medianet Bill payments are only available to Male Region
                customers
              </p>
            </div>
          </div>

          <div class="mx-3 my-4 bg-white shadow p-3">
            <div class="section Dhiraagu active">
              <h5>Medianet Bill Payments</h5>
              <hr />
              <div class="row">
                <div class="col-md-4">
                  <p class="mb-0 fs-14">Enter your Medianet Account Number.</p>
                </div>
                <div class="col-md-8">
                  <label>Account Number</label>
                  <div class="input-group mb-3 ">
                    <span class="input-group-text" id="basic-addon1">
                      <i class="fa-regular fa-credit-card"></i>
                    </span>
                    <input
                      type="number"
                      class="form-control"
                      placeholder="Account Number"
                      value={data.number}
                      onChange={handleChange}
                      name="number"
                    />
                  </div>
                  <label>ID Card Number</label>
                  <div class="input-group mb-3 ">
                    <span class="input-group-text" id="basic-addon1">
                      <i class="fa-regular fa-credit-card"></i>
                    </span>
                    <input
                      type="number"
                      class="form-control"
                      placeholder="ID Card Number"
                      value={data.id_card}
                      onChange={handleChange}
                      name="id_card"
                    />
                  </div>
                  <button
                    onClick={sendRequest}
                    class="btn bg-blue text-light mt-3"
                  >
                    Process
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MediaNet;
