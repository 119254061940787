import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle";

import "./App.css";
import { Route, Routes, Navigate } from "react-router-dom";
import AddBalance from "./pages/AddBalance";
import GiftCards from "./pages/GiftCards";
import History from "./pages/History";
import Home from "./pages/Home";
import Index from "./pages/Index";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import MediaNet from "./pages/MediaNet";
import Recharge from "./pages/Recharge";
import Schedule from "./pages/Schedule";
import cookie from "./utils/cookies";
import ForgotPassword from "./pages/ForgotPassword";
import Sidebar from "./components/Sidebar";

function App() {
  const user = cookie.get("auth");
  return (
    <>
      {/* <Sidebar /> */}
      {!user && (
        <Routes>
          <Route path="/" Component={Index} />
          <Route path="/login" Component={Login} />
          <Route path="/signup" Component={Signup} />
          <Route path="/forgot-password" Component={ForgotPassword} />
          <Route path="/*" element={<Navigate to={"/"} />} />
        </Routes>
      )}
      {user && (
        <Routes>
          <Route path="/dashboard" Component={Home} />
          <Route path="/giftcards" Component={GiftCards} />
          <Route path="/addbalance" Component={AddBalance} />
          <Route path="/history" Component={History} />
          <Route path="/medianet" Component={MediaNet} />
          <Route path="/recharge" Component={Recharge} />
          <Route path="/schedule" Component={Schedule} />
          <Route path="/*" element={<Navigate to={"/dashboard"} />} />
        </Routes>
      )}
    </>
  );
}

export default App;
