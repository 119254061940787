import axios from "axios";
import React, { useState } from "react";

const RechargeSection = () => {
  const [paymentVisible, setPaymentVisible] = useState(false);
  const [data, setData] = useState({
    number: "",
    amount: "",
    method: "Fixed Amount",
    day: "1",
    hour: "00:00",
    repeat: false,
    type: "Rastaas",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleTypeSelectChange = (e) => {
    const selectedValue = e.target.value;
    setPaymentVisible(
      selectedValue === "DhiraaguBillPays" ||
        selectedValue === "DhiraaguTVFiberADSLBillPay"
    );
    handleChange(e);
  };

  const sendRequest = () => {
    axios.post("/requests/schedule/recharge", data).then((res) => {
      if (res) {
        setData({
          number: "",
          amount: "",
          method: "Fixed Amount",
          day: 1,
          hour: "00:00",
          repeat: false,
          type: "Rastaas",
        });
      }
    });
  };

  return (
    <div class="section Recharge active">
      <h5>Schedule Task</h5>
      <hr />
      <div class="row">
        <div class="col-md-4">
          <p class="mb-0 fs-14">Schedule a Reload, Raastas or Bill Payments</p>
        </div>
        <div class="col-md-8">
          <label>Type</label>
          <div class="input-group mb-3">
            <span class="input-group-text" id="basic-addon1">
              <i class="fa-solid fa-arrows"></i>
            </span>
            <select
              className="form-control"
              id="typeSelect"
              onChange={handleTypeSelectChange}
              name="type"
              value={data.type}
            >
              <option value="Rastaas">Rastaas</option>
              <option value="Reload">Reload</option>
              <option value="DhiraaguBillPays">Dhiraagu BillPays</option>
              <option value="DhiraaguTVFiberADSLBillPay">
                Dhiraagu TV / Fiber / ADSL BillPay
              </option>
            </select>
          </div>
          <label>Number</label>
          <div class="input-group mb-3">
            <span class="input-group-text" id="basic-addon1">
              <i class="fa-solid fa-phone"></i>
            </span>
            <input
              type="text"
              class="form-control"
              placeholder="Receiver Number"
              name="number"
              value={data.number}
              onChange={handleChange}
            />
          </div>
          {paymentVisible && (
            <div id="paymentMethodSection">
              <label>Payment Method</label>
              <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon1">
                  <i className="fa-solid fa-phone"></i>
                </span>
                <select
                  value={data.method}
                  name="method"
                  onChange={handleChange}
                  className="form-control"
                >
                  <option value="Fixed Amount">Fixed Amount</option>
                  <option value="Total Outstanding">Total Outstanding</option>
                </select>
              </div>
            </div>
          )}
          <label>Amount</label>
          <div class="input-group mb-3">
            <span class="input-group-text" id="basic-addon1">
              <i class="fa-solid fa-credit-card"></i>
            </span>
            <input
              type="number"
              class="form-control"
              placeholder="Amount"
              value={data.amount}
              onChange={handleChange}
              name="amount"
            />
          </div>
          <label>Day</label>
          <div class="input-group mb-3">
            <span class="input-group-text" id="basic-addon1">
              <i class="fa-regular fa-calendar-days"></i>
            </span>
            <select
              value={data.day}
              onChange={handleChange}
              name="day"
              class="form-control"
            >
              {Array(31)
                .fill()
                .map((_, i) => (
                  <option key={i} value={i + 1}>
                    {i + 1}
                  </option>
                ))}
              <option value="Everyday">Everyday</option>
              <option value="Every Sunday">Every Sunday</option>
              <option value="Every Monday">Every Monday</option>
              <option value="Every Tuesday">Every Tuesday</option>
              <option value="Every Wednesday">Every Wednesday</option>
              <option value="Every Thursday">Every Thursday</option>
              <option value="Every Friday">Every Friday</option>
              <option value="Every Saturday">Every Saturday</option>
            </select>
          </div>
          <label>Hour</label>
          <div class="input-group mb-3">
            <span class="input-group-text" id="basic-addon1">
              <i class="fa-regular fa-clock"></i>
            </span>
            <select
              value={data.hour}
              onChange={handleChange}
              name="hour"
              class="form-control"
            >
              {Array(24)
                .fill()
                .map((_, i) => (
                  <option value={String("0" + i).slice(-2) + ":00"}>
                    {String("0" + i).slice(-2)}:00
                  </option>
                ))}
            </select>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              defaultChecked={data.repeat}
              onChange={(e) => setData({ ...data, repeat: e.target.checked })}
              name="repeat"
              id="flexCheckDefault"
            />
            <label class="form-check-label" for="flexCheckDefault">
              Repeat Task
            </label>
          </div>
          <button onClick={sendRequest} class="btn bg-primary text-light">
            Create
          </button>
        </div>
      </div>
    </div>
  );
};

export default RechargeSection;
