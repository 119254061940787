import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import Dropdown from "../components/Dropdown";
import { Link } from "react-router-dom";
import axios from "axios";
import { HiOutlineBars4 } from "react-icons/hi2";

const Home = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    axios.get("/user/getDashboardData").then((res) => {
      setData(res);
    });
  }, []);
  
  //////////////////////////
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div style={{ backgroundColor: "#ededed" }}>
      <div className="d-flex">
        {isOpen && <Sidebar />}
        <div className="rightSide">
          <div className="bg-Banner">
            <div className="d-flex justify-content-between mx-5 align-items-center py-3">
              <div className="d-flex align-items-center gap-2">
                <HiOutlineBars4
                  size={23}
                  onClick={toggleSidebar}
                  className="text-light cursor-pointer"
                />
                <h6 class="text-light mb-0">VAGUTHUN RECHARGE</h6>
              </div>{" "}
              <Dropdown />
            </div>
            <div className="mt-5">
              <h1 className="fw-bold text-center text-light">Welcome Back</h1>
              <p className="fs-5 mb-0 text-center text-light">
                Enjoy using Vaguthun Recharge
              </p>
            </div>
          </div>
          <div className="mx-3 my-4">
            <div className="row justify-content-center">
              <div className="col-md-4">
                <div
                  style={{ position: "relative" }}
                  className="bg-light rounded cursor-pointer d-flex flex-column justify-content-center align-items-center py-5"
                >
                  <span
                    className="bg-blue px-2 py-1 text-light"
                    style={{ position: "absolute", left: "0%", top: "10%" }}
                  >
                    MVR
                  </span>
                  <h5>{data?.balance || 0}</h5>
                  <h6>Balance</h6>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  style={{ position: "relative" }}
                  className="bg-light rounded cursor-pointer d-flex flex-column justify-content-center align-items-center py-5"
                >
                  <span
                    className="bg-green px-2 py-1 text-light"
                    style={{ position: "absolute", left: "0%", top: "10%" }}
                  >
                    MVR
                  </span>
                  <h5>{data?.recharge || 0}</h5>
                  <h6>Recharges</h6>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  style={{ position: "relative" }}
                  className="bg-light rounded cursor-pointer d-flex flex-column justify-content-center align-items-center py-5"
                >
                  <span
                    className="px-2 py-1 text-light bg-orange"
                    style={{ position: "absolute", left: "0%", top: "10%" }}
                  >
                    MVR
                  </span>
                  <h5>{data?.payment || 0}</h5>
                  <h6>Payments</h6>
                </div>
              </div>
            </div>
            <div className="row justify-content-center py-4">
              <div className="col-md-6">
                <Link to={"/addbalance"}>
                  <div className="bg-light rounded cursor-pointer d-flex flex-column justify-content-center gap-3 align-items-center py-5">
                    <i className="fa-solid fa-wallet fs-1"></i>
                    <small>Add Balance</small>
                  </div>
                </Link>
              </div>
              <div className="col-md-6">
                <Link to={"/recharge"}>
                  <div className="bg-light rounded cursor-pointer d-flex flex-column justify-content-center gap-3 align-items-center py-5">
                    <i className="fa-solid fa-bolt fs-1"></i>
                    <small>Recharge & Billpays</small>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
