import React from "react";

const Faq = () => {
  return (
    <>
      <div style={{ backgroundColor: "#efefef" }}>
        <div class="container py-5">
          <h1 class="mb-4 text-center fw-bold">Frequently Asked Questions</h1>
          <div class="accordion" id="accordionExample">
            <div class="mb-3">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button shadow-none bg-transparent text-dark"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    How to add balance to Vaguthun Recharge(ANDROID/WEB)?
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    If you are using “Vaguthun Recharge” for the first time you
                    will be required to enter Bank Account Name. To set Account
                    name simply Go to “Add Balance” Page and click “My Account”
                    and enter Account holder’s full name. If you have already
                    finished account name setup, You will need to transfer Money
                    to our bank account number given in “ADD BALANCE” page from
                    “BML INTERNET BANKING” , After making transfer enter amount
                    you have transferred to our bank account number given in
                    “AMOUNT” box and click “ADD ” to get Vaguthun Recharge
                    balance.
                    <br />
                    <br />
                    NOTE: Bank account name will be Account holder’s full name
                    as in “National Identity card or Passport”
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-3">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button shadow-none bg-transparent text-dark collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    How to Add Balance to Vaguthun Recharge(IOS)?
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    If you are using “Vaguthun Recharge” for the first time you
                    will be required to enter Bank Account Name. To set Account
                    name simply Go to “Add Balance” Page and click “My Account”
                    and enter Account holder’s full name. If you have already
                    finished account name setup, You will need to transfer Money
                    to our bank account number given in “ADD BALANCE” page from
                    “BML INTERNET BANKING” , After making transfer enter amount
                    you have transferred to our bank account number given in
                    “AMOUNT” box and click “ADD ” to get Vaguthun Recharge
                    balance.
                    <br />
                    <br />
                    NOTE: Bank account name will be Account holder’s full name
                    as in “National Identity card or Passport”
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-3">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingThree">
                  <button
                    class="accordion-button shadow-none bg-transparent text-dark collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    How can I recharge or pay my bill after adding balance to
                    Vaguthun Recharge?
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    If you are using “Vaguthun Recharge” for the first time you
                    will be required to enter Bank Account Name. To set Account
                    name simply Go to “Add Balance” Page and click “My Account”
                    and enter Account holder’s full name. If you have already
                    finished account name setup, You will need to transfer Money
                    to our bank account number given in “ADD BALANCE” page from
                    “BML INTERNET BANKING” , After making transfer enter amount
                    you have transferred to our bank account number given in
                    “AMOUNT” box and click “ADD ” to get Vaguthun Recharge
                    balance.
                    <br />
                    <br />
                    NOTE: Bank account name will be Account holder’s full name
                    as in “National Identity card or Passport”
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-3">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingFour">
                  <button
                    class="accordion-button shadow-none bg-transparent text-dark collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    How long will it take to process Recharge or Bill Payments?
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    If you are using “Vaguthun Recharge” for the first time you
                    will be required to enter Bank Account Name. To set Account
                    name simply Go to “Add Balance” Page and click “My Account”
                    and enter Account holder’s full name. If you have already
                    finished account name setup, You will need to transfer Money
                    to our bank account number given in “ADD BALANCE” page from
                    “BML INTERNET BANKING” , After making transfer enter amount
                    you have transferred to our bank account number given in
                    “AMOUNT” box and click “ADD ” to get Vaguthun Recharge
                    balance.
                    <br />
                    <br />
                    NOTE: Bank account name will be Account holder’s full name
                    as in “National Identity card or Passport”
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-3">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingFive">
                  <button
                    class="accordion-button shadow-none bg-transparent text-dark collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    Can I trust “Vaguthun Recharge”?
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFive"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    If you are using “Vaguthun Recharge” for the first time you
                    will be required to enter Bank Account Name. To set Account
                    name simply Go to “Add Balance” Page and click “My Account”
                    and enter Account holder’s full name. If you have already
                    finished account name setup, You will need to transfer Money
                    to our bank account number given in “ADD BALANCE” page from
                    “BML INTERNET BANKING” , After making transfer enter amount
                    you have transferred to our bank account number given in
                    “AMOUNT” box and click “ADD ” to get Vaguthun Recharge
                    balance.
                    <br />
                    <br />
                    NOTE: Bank account name will be Account holder’s full name
                    as in “National Identity card or Passport”
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Faq;
