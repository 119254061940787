import React, { useEffect, useState } from "react";
import Dropdown from "../components/Dropdown";
import Sidebar from "../components/Sidebar";
import axios from "axios";
import BASE_URL from "../utils/constants";
import { HiOutlineBars4 } from "react-icons/hi2";

const History = () => {
  const [showRecharge, setShowRecharge] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    axios.get("/requests/getUserHistory").then((res) => {
      setData(res);
    });
  }, []);

  //////////////////////////
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <div className="d-flex bg-light">
        {isOpen && <Sidebar />}
        <div className="rightSide">
          <div className="bg-Banner">
            <div className="d-flex justify-content-between mx-5 align-items-center py-3">
              <div className="d-flex align-items-center gap-2">
                <HiOutlineBars4
                  size={23}
                  onClick={toggleSidebar}
                  className="text-light cursor-pointer"
                />
                <h6 class="text-light mb-0">VAGUTHUN RECHARGE</h6>
              </div>{" "}
              <Dropdown />
            </div>
            <div className="mt-5">
              <h1 className="fw-bold text-center text-light">History</h1>
              <p className="fs-5 mb-0 text-center text-light">
                My Recharges & Payments
              </p>
            </div>
          </div>

          <div className="mx-3 my-4 bg-white shadow p-3">
            <div className="d-flex gap-2">
              <p
                className={`mb-0 p-2 cursor-pointer ${
                  showRecharge ? "bg-secondary text-white" : ""
                }`}
                onClick={() => setShowRecharge(true)}
              >
                Recharges
              </p>
              <p
                className={`mb-0 p-2 cursor-pointer ${
                  !showRecharge ? "bg-secondary text-white" : ""
                }`}
                onClick={() => setShowRecharge(false)}
              >
                Payments
              </p>
            </div>
            {showRecharge ? (
              /* Recharges Section */
              <div className="recharge">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Phone Number</th>
                      <th scope="col">Type</th>
                      <th scope="col">Service</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Completed</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.requests?.map((data, i) => (
                      <tr>
                        <th scope="row">{++i}</th>
                        <td>{data.phone}</td>
                        <td>{data.type}</td>
                        <td>{data.service}</td>
                        <td>{data.amount}</td>
                        <td>{data.isDone ? "Yes" : "No"}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              /* Payments Section */
              <div className="payment">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Type</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Receipt</th>
                      <th scope="col">Date</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.balanceRequests?.map((data, i) => (
                      <tr>
                        <th scope="row">{++i}</th>
                        <td>{data.type}</td>
                        <td>{data.amount}</td>
                        <td>
                          <a
                            className="text-primary"
                            href={`${BASE_URL}/${data.receipt}`}
                            target="_blank"
                          >
                            View
                          </a>
                        </td>
                        <td>{new Date(data.createdAt).toDateString()}</td>
                        <td>{data.isDone ? "Completed" : "Pending"}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default History;
