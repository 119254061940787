import React, { useEffect, useState } from "react";
import Dropdown from "../components/Dropdown";
import Sidebar from "../components/Sidebar";
import { HiOutlineBars4 } from "react-icons/hi2";

import axios from "axios";
import { toast } from "react-toastify";
import { getPackageById, ooredooPackages, packages } from "../utils/packages";

const Recharge = () => {
  const [showDhiraagu, setShowDhiraagu] = useState(true);
  const [rechargeData, setRechargeData] = useState({
    phone: "",
    amount: "",
  });
  const [packageData, setPackageData] = useState({
    phone: "",
    package: 1,
  });

  const [selectedOption, setSelectedOption] = useState("reload");

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleRechargeChange = (e) => {
    const { name, value } = e.target;
    setRechargeData({ ...rechargeData, [name]: value });
  };

  const handlePackageChange = (e) => {
    const { name, value } = e.target;
    setPackageData({ ...packageData, [name]: value });
  };

  useEffect(() => {
    if (showDhiraagu) {
      setSelectedOption("reload");
    } else {
      setSelectedOption("raastas");
    }
  }, [showDhiraagu]);

  const requestRecharge = () => {
    if (
      rechargeData.phone === "" ||
      rechargeData.phone.length !== 7 ||
      (rechargeData.phone.split("")[0] != "7" &&
        rechargeData.phone.split("")[0] != "9")
    ) {
      return toast.error("Invalid Phone Number");
    }
    axios
      .post("/requests/recharge", {
        ...rechargeData,
        service: showDhiraagu ? "dhiraagu" : "ooredoo",
        type: selectedOption,
      })
      .then((res) => {
        setRechargeData({
          phone: "",
          amount: "",
        });
      });
  };

  const requestPackage = () => {
    if (
      packageData.phone === "" ||
      packageData.phone.length !== 7 ||
      (packageData.phone.split("")[0] != "7" &&
        packageData.phone.split("")[0] != "9")
    ) {
      return toast.error("Invalid Phone Number");
    }

    const pack = getPackageById(packageData.package, showDhiraagu);
    axios
      .post("/requests/package", {
        ...packageData,
        package: `${pack.name} (${pack.price} MVR)`,
        packagePrice: pack.price,
      })
      .then((res) => {
        setPackageData({
          phone: "",
          package: 1,
        });
      });
  };
  //////////////////////////
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div class="d-flex bg-light">
        {isOpen && <Sidebar />}
        <div class="rightSide">
          <div class="bg-Banner">
            <div class="d-flex justify-content-between mx-5 align-items-center py-3">
              <div className="d-flex align-items-center gap-2">
                <HiOutlineBars4
                  size={23}
                  onClick={toggleSidebar}
                  className="text-light cursor-pointer"
                />
                <h6 class="text-light mb-0">VAGUTHUN RECHARGE</h6>
              </div>
              <Dropdown />
            </div>
            <div class="mt-5">
              <h1 class="fw-bold text-center text-light">Recharge</h1>
              <p class="fs-5 mb-0 text-center text-light">
                Instant Recharge and Data Packages
              </p>
            </div>
          </div>

          <div class="mx-3 my-4 bg-white shadow p-3">
            <div class="d-flex gap-2 mb-3">
              <p
                className={`mb-0 p-2 cursor-pointer ${
                  showDhiraagu ? "bg-light" : ""
                }`}
                onClick={() => setShowDhiraagu(true)}
              >
                Dhiraagu
              </p>
              <p
                className={`mb-0 p-2 cursor-pointer ${
                  !showDhiraagu ? "bg-light" : ""
                }`}
                onClick={() => setShowDhiraagu(false)}
              >
                Ooredoo
              </p>
            </div>
            {showDhiraagu ? (
              <div className="Dhiraagu">
                <h5>Reload & Bill Pay</h5>
                <hr />
                <div className="row">
                  <div className="col-md-4">
                    <p className="mb-0 fs-14">
                      Reload, Bill Pay to any Dhiraagu number instantly.
                    </p>
                  </div>
                  <div className="col-md-8">
                    <label>Mobile Number</label>
                    <div className="input-group mb-3 ">
                      <span className="input-group-text" id="basic-addon1">
                        <i className="fa-solid fa-phone"></i>
                      </span>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Mobile Number"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        name="phone"
                        value={rechargeData.phone}
                        onChange={handleRechargeChange}
                      />
                    </div>
                    <label>Amount</label>
                    <div className="input-group mb-3 ">
                      <span className="input-group-text" id="basic-addon1">
                        <i className="fa-regular fa-credit-card"></i>
                      </span>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Amount"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        name="amount"
                        value={rechargeData.amount}
                        onChange={handleRechargeChange}
                      />
                    </div>
                    {selectedOption === "reload" && rechargeData.amount < 8 && (
                      <p className="text-danger" id="reloadText">
                        Minimum Reload amount is 8 MVR
                      </p>
                    )}
                    <p
                      className="text-danger"
                      id="billPayText"
                      style={{
                        display:
                          selectedOption === "Dhiraagy BillPay"
                            ? "block"
                            : "none",
                      }}
                    >
                      Minimum Dhiraagu BillPay amount is 1 MVR
                    </p>
                    {selectedOption === "DhiraaguPay Cash-in" &&
                      rechargeData.amount < 1 && (
                        <p className="text-danger" id="cashInText">
                          Minimum DhiraaguPay Cash-in amount is 1 MVR
                        </p>
                      )}
                    {selectedOption === "Dhiraagu TV / Fiber / ADSL BillPay" &&
                      rechargeData.amount < 1 && (
                        <p className="text-danger" id="tvBillText">
                          Minimum Dhiraagu TV / Fiber / ADSL BillPay amount is 1
                          MVR
                        </p>
                      )}
                    <div className="mt-2">
                      <p className="mb-0">Type</p>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="type"
                          id="flexRadioDefault1"
                          onChange={handleRadioChange}
                          value="reload"
                          checked={selectedOption === "reload"}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault1"
                        >
                          Reload
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="type"
                          id="flexRadioDefault2"
                          onChange={handleRadioChange}
                          value={"Dhiraagy BillPay"}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault2"
                        >
                          Dhiraagu Bill Pay
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="type"
                          id="flexRadioDefault3"
                          onChange={handleRadioChange}
                          value={"DhiraaguPay Cash-in"}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault3"
                        >
                          DhiraaguPay Cash-in
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="type"
                          id="flexRadioDefault4"
                          onChange={handleRadioChange}
                          value={"Dhiraagu TV / Fiber / ADSL BillPay"}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault4"
                        >
                          Dhiraagu TV / Fiber / ADSL BillPay
                        </label>
                      </div>
                    </div>
                    <button
                      className="btn bg-blue text-light mt-3"
                      onClick={requestRecharge}
                    >
                      Process
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="ooredoo">
                <h5>Raastas & Data Packages</h5>
                <hr />
                <div class="row">
                  <div class="col-md-4">
                    <p class="mb-0 fs-14">
                      Raastas & activate Data Packages to any Ooredoo number
                      instantly.
                    </p>
                  </div>
                  <div class="col-md-8">
                    <label>Mobile Number</label>
                    <div class="input-group mb-3 ">
                      <span class="input-group-text" id="basic-addon1">
                        <i class="fa-solid fa-phone"></i>
                      </span>
                      <input
                        type="number"
                        class="form-control"
                        placeholder="Mobile Number"
                        aria-describedby="basic-addon1"
                        value={rechargeData.phone}
                        onChange={handleRechargeChange}
                        name="phone"
                      />
                    </div>
                    <label>Amount</label>
                    <div class="input-group mb-3 ">
                      <span class="input-group-text" id="basic-addon1">
                        <i class="fa-regular fa-credit-card"></i>
                      </span>
                      <input
                        type="number"
                        class="form-control"
                        placeholder="Amount"
                        aria-describedby="basic-addon1"
                        value={rechargeData.amount}
                        onChange={handleRechargeChange}
                        name="amount"
                      />
                    </div>
                    {selectedOption === "raastas" &&
                      rechargeData.amount < 11 && (
                        <p class="text-danger">
                          Minimum Raastas amount is 11 MVR
                        </p>
                      )}
                    <p class="mb-0">Type</p>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="type"
                        id="flexRadioDefault2"
                        checked
                        value={"raastas"}
                        onChange={handleRadioChange}
                      />
                      <label class="form-check-label" for="flexRadioDefault2">
                        Raastas
                      </label>
                    </div>
                    <button
                      onClick={requestRecharge}
                      class="btn bg-blue text-light mt-3"
                    >
                      Request
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div class="mx-3 my-4 bg-white shadow p-3">
            <h5>{showDhiraagu ? "Dhiraagu" : "Ooredoo"} Packages</h5>
            <hr />
            <div class="row">
              <div class="col-md-4">
                <p class="mb-0 fs-14">Activate any package of your choice.</p>
              </div>
              <div class="col-md-8">
                <label>Mobile Number</label>
                <div class="input-group mb-3 ">
                  <span class="input-group-text" id="basic-addon1">
                    <i class="fa-solid fa-phone"></i>
                  </span>
                  <input
                    type="number"
                    class="form-control"
                    placeholder="Mobile Number"
                    value={packageData.phone}
                    name="phone"
                    onChange={handlePackageChange}
                  />
                </div>
                <label>Package</label>
                <div class="input-group mb-3 ">
                  <span class="input-group-text" id="basic-addon1">
                    <i class="fa-brands fa-dropbox"></i>
                  </span>
                  <select
                    value={packageData.package}
                    onChange={handlePackageChange}
                    class="form-control"
                    name="package"
                  >
                    {showDhiraagu &&
                      packages.map((pack) => (
                        <option value={pack.id}>
                          {pack.name} ({pack.price} MVR)
                        </option>
                      ))}
                    {!showDhiraagu &&
                      ooredooPackages.map((pack) => (
                        <option value={pack.id}>
                          {pack.name} ({pack.price} MVR)
                        </option>
                      ))}
                  </select>
                </div>
                <button
                  onClick={requestPackage}
                  class="btn bg-blue text-light mt-3"
                >
                  Request
                </button>
              </div>
            </div>
            {/* <div>
              <div class="d-flex justify-content-between align-items-center mt-3">
                <div class="d-flex align-items-center gap-4">
                  <p class="mb-0 ">#</p>
                  <p class="mb-0">PACKAGE</p>
                </div>
                <p class="mb-0">PRICE</p>
              </div>
              <hr />
              <div class="d-flex justify-content-between align-items-center mt-3">
                <div class="d-flex align-items-center gap-4">
                  <p class="mb-0 ">1</p>
                  <div>
                    <p class="mb-0">Dhiraagu Daily Unlimited</p>
                    <small class="mb-0">
                      Unlimited Dhiraagu Data Package for Just MVR 108, Package
                      will be valid Till 23:59:59 of Same Day{" "}
                    </small>
                  </div>
                </div>
                <p class="mb-0">108 MVR</p>
              </div>
              <hr />
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Recharge;
