import React, { useState } from "react";
import axios from "axios";
import cookie from "../utils/cookies";
const OTP = ({ mobileNumber, password }) => {
  const [otp, setOTP] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = await axios.post("/auth/register", {
      mobileNumber,
      otp,
      password,
    });
    if (res) {
      if (res) {
        cookie.set("auth", res);
        window.location.href = "/dashboard";
      }
    }
  };
  return (
    <div class="container-login container">
      <div class="text">Enter OTP</div>
      <form onSubmit={handleSubmit}>
        <div class="form-row">
          <div class="input-data">
            <input
              type="number"
              name="otp"
              value={otp}
              onChange={(e) => setOTP(e.target.value)}
              required
              min={0}
            />
            <div class="underline"></div>
            <label for="">OTP</label>
          </div>
        </div>

        <button
          type="submit"
          class="btn text-light bg-blue rounded-0 ms-4 px-4 py-2 my-3"
        >
          Signup
        </button>
      </form>
    </div>
  );
};

export default OTP;
