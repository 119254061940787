// import React from "react";

// const CountUp = () => {
//     // ----------------------increment of number
// function incrementNumber1() {
//     var numberElement = document.getElementById("numberDisplay1");
//     var number = parseInt(numberElement.textContent);

//     if (number < 15000) {
//       number += 50; // Increase by 50 instead of 1
//       numberElement.textContent = number;
//       setTimeout(incrementNumber1, 1); // Adjust the timeout value for speed
//     }
//   }

//   function incrementNumber2() {
//     var numberElement = document.getElementById("numberDisplay2");
//     var number = parseInt(numberElement.textContent);

//     if (number < 5000) {
//       number += 20; // Increase by 100 instead of 1
//       numberElement.textContent = number;
//       setTimeout(incrementNumber2, 1); // Adjust the timeout value for speed
//     }
//   }

//   function incrementNumber3() {
//     var numberElement = document.getElementById("numberDisplay3");
//     var number = parseInt(numberElement.textContent);

//     if (number < 10000) {
//       number += 50; // Increase by 500 instead of 1
//       numberElement.textContent = number;
//       setTimeout(incrementNumber3, 1); // Adjust the timeout value for speed
//     }
//   }

//   function incrementNumber4() {
//     var numberElement = document.getElementById("numberDisplay4");
//     var number = parseInt(numberElement.textContent);

//     if (number < 1000) {
//       number += 5; // Increase by 1000 instead of 1
//       numberElement.textContent = number;
//       setTimeout(incrementNumber4, 1); // Adjust the timeout value for speed
//     }
//   }

//   window.onload = function () {
//     // Call all increment functions
//     incrementNumber1();
//     incrementNumber2();
//     incrementNumber3();
//     incrementNumber4();

//   return (
//     <>
//       <div class="container py-5">
//         <div class="row justify-content-center">
//           <div class="col-md-3 d-flex flex-column justify-content-center align-items-center">
//             <i class="text-blue fs-1 mb-4 fa-regular fa-user"></i>
//             <h2 class="fw-bold" id="numberDisplay1">
//               0
//             </h2>
//             <h6 class="text-secondary">ANDROID DOWNLOADS</h6>
//           </div>
//           <div class="col-md-3 d-flex flex-column justify-content-center align-items-center">
//             <i class="text-blue fs-1 mb-4 fa-regular fa-user"></i>
//             <h2 class="fw-bold" id="numberDisplay2">
//               0
//             </h2>
//             <h6 class="text-secondary">IOS DOWNLOADS</h6>
//           </div>
//           <div class="col-md-3 d-flex flex-column justify-content-center align-items-center">
//             <i class="text-blue fs-1 mb-4 fa-regular fa-clock"></i>
//             <h2 class="fw-bold" id="numberDisplay3">
//               0
//             </h2>
//             <h6 class="text-secondary">ACTIVE USERS</h6>
//           </div>
//           <div class="col-md-3 d-flex flex-column justify-content-center align-items-center">
//             <i class="text-blue fs-1 mb-4 fa-solid fa-award"></i>
//             <h2 class="fw-bold" id="numberDisplay4">
//               0
//             </h2>
//             <h6 class="text-secondary">POSITIVE RATING</h6>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default CountUp;
import React, { useState, useEffect } from "react";

const CountUp = () => {
  const [numbers, setNumbers] = useState({
    number1: 0,
    number2: 0,
    number3: 0,
    number4: 0,
  });

  useEffect(() => {
    const incrementNumbers = () => {
      setNumbers((prevNumbers) => ({
        number1: prevNumbers.number1 < 15000 ? prevNumbers.number1 + 50 : 15000,
        number2: prevNumbers.number2 < 5000 ? prevNumbers.number2 + 20 : 5000,
        number3: prevNumbers.number3 < 10000 ? prevNumbers.number3 + 50 : 10000,
        number4: prevNumbers.number4 < 1000 ? prevNumbers.number4 + 5 : 1000,
      }));
    };

    const interval = setInterval(incrementNumbers, 1); // Adjust the interval for speed

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="container py-5">
      <div className="row justify-content-center">
        <div className="col-md-3 d-flex flex-column justify-content-center align-items-center">
          <i className="text-blue fs-1 mb-4 fa-regular fa-user"></i>
          <h2 className="fw-bold">{numbers.number1}</h2>
          <h6 className="text-secondary">ANDROID DOWNLOADS</h6>
        </div>
        <div className="col-md-3 d-flex flex-column justify-content-center align-items-center">
          <i className="text-blue fs-1 mb-4 fa-regular fa-user"></i>
          <h2 className="fw-bold">{numbers.number2}</h2>
          <h6 className="text-secondary">IOS DOWNLOADS</h6>
        </div>
        <div className="col-md-3 d-flex flex-column justify-content-center align-items-center">
          <i className="text-blue fs-1 mb-4 fa-regular fa-clock"></i>
          <h2 className="fw-bold">{numbers.number3}</h2>
          <h6 className="text-secondary">ACTIVE USERS</h6>
        </div>
        <div className="col-md-3 d-flex flex-column justify-content-center align-items-center">
          <i className="text-blue fs-1 mb-4 fa-solid fa-award"></i>
          <h2 className="fw-bold">{numbers.number4}</h2>
          <h6 className="text-secondary">POSITIVE RATING</h6>
        </div>
      </div>
    </div>
  );
};

export default CountUp;
