// import React, { useEffect, useState } from "react";

// const Testimonial = () => {
//   const [currentSlide, setCurrentSlide] = useState(0);
//   const [touchStartPos, setTouchStartPos] = useState(null);
//   const [touchEndPos, setTouchEndPos] = useState(null);
//   const testimSpeed = 5000; // Adjust this value as needed
//   const ignoreTouch = 50; // Adjust this value as needed
//   let testimTimer = null;

//   const playSlide = (slide) => {
//     const nextSlide = (slide + 5) % 5; // Assuming you have 5 slides
//     setCurrentSlide(nextSlide);
//   };

//   useEffect(() => {
//     const handleSlideChange = () => {
//       clearTimeout(testimTimer);
//       testimTimer = setTimeout(() => {
//         playSlide(currentSlide + 1);
//       }, testimSpeed);
//     };

//     handleSlideChange(); // Initial slide change

//     return () => {
//       clearTimeout(testimTimer);
//     };
//   }, [currentSlide]);

//   const handleDotClick = (index) => {
//     setCurrentSlide(index);
//   };

//   const handleTouchStart = (e) => {
//     setTouchStartPos(e.changedTouches[0].clientX);
//   };

//   const handleTouchEnd = (e) => {
//     setTouchEndPos(e.changedTouches[0].clientX);
//     const touchPosDiff = touchStartPos - touchEndPos;

//     if (touchPosDiff > ignoreTouch) {
//       playSlide(currentSlide - 1);
//     } else if (touchPosDiff < -ignoreTouch) {
//       playSlide(currentSlide + 1);
//     }
//   };

//   return (
//     <>
//       <div className="container py-5">
//         <div
//           className="row text-center mb-4"
//           onTouchStart={handleTouchStart}
//           onTouchEnd={handleTouchEnd}
//         >
//           <div className="col-12">
//             <h1 className="fw-bold">Testimonial</h1>
//           </div>
//         </div>
//         <section className="testimonial-section2">
//           <div id="testim" className="testim">
//             <div className="wrap">
//               <span
//                 id="right-arrow"
//                 className="arrow right fa fa-chevron-right"
//               ></span>
//               <span
//                 id="left-arrow"
//                 className="arrow left fa fa-chevron-left"
//               ></span>
//               <ul id="testim-dots" className="dots">
//                 {[0, 1, 2, 3, 4].map((index) => (
//                   <li
//                     key={index}
//                     className={`dot ${currentSlide === index ? "active" : ""}`}
//                     onClick={() => handleDotClick(index)}
//                   ></li>
//                 ))}
//               </ul>
//               <div id="testim-content" className="cont">
//                 {[0, 1, 2, 3, 4].map((index) => (
//                   <div
//                     key={index}
//                     className={`testimonial-item ${
//                       currentSlide === index ? "active" : ""
//                     }`}
//                   >
//                     <div className="img">
//                       <img src={avatar} alt="" />
//                     </div>
//                     <div className="h4">
//                       {index % 2 === 0 ? "Kellie" : "Jessica"}
//                     </div>
//                     <div className="d-flex gap-1"></div>
//                     <p>
//                       {[1, 2, 3, 4, 5].map((star) => (
//                         <i key={star} className="fa-solid fa-star"></i>
//                       ))}
//                       <br />
//                       <br />
//                       Lorem ipsum dolor sit amet, consectetur adipiscing elit,
//                       sed do eiusmod tempor incididunt ut labore et dolore magna
//                       aliqua. Ut enim ad minim veniam, quis nostrud exercitation
//                       ullamco.
//                     </p>
//                   </div>
//                 ))}
//               </div>
//             </div>
//           </div>
//         </section>
//       </div>
//     </>
//   );
// };

// export default Testimonial;

import React, { useEffect, useState } from "react";
import avatar from "../../images/avatar.jpg"

const Testimonial = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [touchStartPos, setTouchStartPos] = useState(null);
  const [touchEndPos, setTouchEndPos] = useState(null);
  const testimSpeed = 5000; // Adjust this value as needed
  const ignoreTouch = 50; // Adjust this value as needed

  const playSlide = (slide) => {
    const nextSlide = (slide + 5) % 5; // Assuming you have 5 slides
    setCurrentSlide(nextSlide);
  };

  useEffect(() => {
    const testimTimer = setInterval(() => {
      playSlide(currentSlide + 1);
    }, testimSpeed);

    return () => {
      clearInterval(testimTimer);
    };
  }, [currentSlide]);

  const handleDotClick = (index) => {
    setCurrentSlide(index);
  };

  const handleTouchStart = (e) => {
    setTouchStartPos(e.changedTouches[0].clientX);
  };

  const handleTouchEnd = (e) => {
    setTouchEndPos(e.changedTouches[0].clientX);
    const touchPosDiff = touchStartPos - touchEndPos;

    if (touchPosDiff > ignoreTouch) {
      playSlide(currentSlide - 1);
    } else if (touchPosDiff < -ignoreTouch) {
      playSlide(currentSlide + 1);
    }
  };

  const handleArrowClick = (direction) => {
    if (direction === "left") {
      playSlide(currentSlide - 1);
    } else if (direction === "right") {
      playSlide(currentSlide + 1);
    }
  };

  return (
    <>
      <div className="container py-5">
        <div
          className="row text-center mb-4"
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
        >
          <div className="col-12">
            <h1 className="fw-bold">Testimonial</h1>
          </div>
        </div>
        <section className="testimonial-section2">
          <div id="testim" className="testim">
            <div className="wrap">
              <span
                id="left-arrow"
                className="arrow left fa fa-chevron-left"
                onClick={() => handleArrowClick("left")}
              ></span>
              <span
                id="right-arrow"
                className="arrow right fa fa-chevron-right"
                onClick={() => handleArrowClick("right")}
              ></span>
              <ul id="testim-dots" className="dots">
                {[0, 1, 2, 3, 4].map((index) => (
                  <li
                    key={index}
                    className={`dot ${currentSlide === index ? "active" : ""}`}
                    onClick={() => handleDotClick(index)}
                  ></li>
                ))}
              </ul>
              <div id="testim-content" className="cont">
                {[0, 1, 2, 3, 4].map((index) => (
                  <div
                    key={index}
                    className={`testimonial-item ${
                      currentSlide === index ? "active" : ""
                    }`}
                  >
                    <div className="img">
                      <img src={avatar} alt="" />
                    </div>
                    <div className="h4">
                      {index % 2 === 0 ? "Kellie" : "Jessica"}
                    </div>
                    <div className="d-flex gap-1"></div>
                    <p>
                      {[1, 2, 3, 4, 5].map((star) => (
                        <i key={star} className="fa-solid fa-star"></i>
                      ))}
                      <br />
                      <br />
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco.
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Testimonial;
