import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import cookie from "../utils/cookies";

const Login = () => {
  const [formData, setFormData] = useState({
    mobileNumber: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = await axios.post("/auth/login", formData);
    if (res) {
      cookie.set("auth", res);
      window.location.href = "/dashboard";
    }
  };

  return (
    <div className="main_login">
      <div class="container-login container">
        <div class="text">Let's Sign In</div>
        <form onSubmit={handleSubmit}>
          <div class="form-row">
            <div class="input-data">
              <input
                type="number"
                name="mobileNumber"
                value={formData.mobileNumber}
                onChange={handleChange}
                required
              />
              <div class="underline"></div>
              <label for="">Mobile Number</label>
            </div>
          </div>
          <div class="form-row">
            <div class="input-data">
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
              />
              <div class="underline"></div>
              <label for="">Password</label>
            </div>
          </div>
          <div class="mb-3 form-check ms-4">
            <input
              type="checkbox"
              class="form-check-input"
              id="exampleCheck1"
            />
            <label class="form-check-label" for="exampleCheck1">
              Check me out
            </label>
          </div>
          {/* <div class="form-row">
          <div class="input-data textarea">
            <div class="form-row submit-btn">
              <div class="input-data">
                <div class="inner"></div>
                <input type="submit" value="submit" />
              </div>
            </div>
          </div>
        </div>  */}
          <div class="d-flex align-items-center py-3">
            <div class="text-blue px-4 d-flex gap-2 align-items-center">
              <i class="fa-solid fa-arrow-right-to-bracket"></i>
              <Link to="/signup" class="hoverUnderline">
                Sign Up
              </Link>
            </div>
            <div class="text-blue px-4 d-flex gap-2 align-items-center">
              <span>*</span>
              <Link to="/forgot-password" class="hoverUnderline">
                Forgot Password?
              </Link>
            </div>
          </div>
          <button
            type="submit"
            class="btn text-light bg-blue rounded-0 ms-4 px-4 py-2 my-3"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
