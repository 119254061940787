import React from "react";
import { Link } from "react-router-dom";

const HeroSection = () => {
  return (
    <>
      <div class="hero_sectionBg">
        <div>
          <h1 class="text-light fw-bold text-center">VAGUTHUN RECHARGE</h1>
          <p class="text-light text-center fs-4">
            Raastas, Reload & Pay The Bills Wtihin Few Steps !
          </p>
          <div class="d-flex justify-content-center gap-3">
            <Link
              to="/login"
              class="btn col-md-5 text-light py-3 fw-bold bg-green"
            >
              Sign In
            </Link>
            <Link
              to="/signup"
              class="bg-blue btn col-md-5 text-light py-3 fw-bold"
            >
              Sign Up
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroSection;
