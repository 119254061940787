import React, { useEffect, useState } from "react";
import Testimonial from "../components/index/Testimonial";
import Faq from "../components/index/Faq";
import Services from "../components/index/Services";
import CountUp from "../components/index/CountUp";
import HeroSection from "../components/index/HeroSection";

const Index = () => {
  return (
    <>
      <HeroSection />
      <CountUp />
      <Services />
      {/* <!-- install feature --> */}
      {/* <div class="col-md-6 mx-auto py-5">
        <h2 class="text-center fw-bold">Availabe on Android & iOS</h2>
        <p class="fs-5 text-center text-secondary">
          Install and enjoy our service now!
        </p>
        <div class="d-flex justify-content-center gap-3">
          <button
            class="btn px-5 text-light py-2 fw-bold"
            style="background-color: #9ccc65"
          >
            Android
          </button>
          <button class="bg-blue btn px-5 text-light py-2 fw-bold">IOS</button>
        </div>
      </div> */}
      <Faq />
      <div class="col-md-9 mx-auto mt-5 mb-4">
        <h1 class="text-center fw-bold">Whats New</h1>
        <p class="fs-5 text-center text-secondary">
          We support all recently intoduced services by Opertators.
        </p>
        <hr class="text-secondary" />
      </div>
      <div class="container">
        <div class="row">
          <div class="col-md-4 mb-5">
            <h5 class="fw-bold">Vaguthun HOME BROADBAND</h5>
            <p>Activate Vaguthun Home Broadband Packages Online & Instantly.</p>
          </div>
          <div class="col-md-4 mb-5">
            <h5 class="fw-bold">OOREDOO APPNGAME</h5>
            <p>Activate Ooredoo AppNGame Packages Online & Instantly.</p>
          </div>
          <div class="col-md-4 mb-5">
            <h5 class="fw-bold">VAGUTHUN GEYAH</h5>
            <p>
              You can activate Dhiraagu Vaguthun Geyah data Packages Online &
              Instantly.
            </p>
          </div>
        </div>
      </div>
      <Testimonial />
      {/* <!-- ------------------------- contact us ---------------------------------- --> */}
      <div class="col-md-9 mx-auto mb-5">
        <h2 class="fw-bold text-center mt-5">Contact Us</h2>
        <p class="fs-5 text-secondary text-center">
          Make your life easier and work more efficiently.
        </p>
        <hr class="text-secondary" />
      </div>
      <div class="container">
        <div class="row">
          <div class="col-md-4 mb-5">
            <h5 class="fw-bold">PHONE</h5>
            <p class="text-blue">+9604003330</p>
          </div>
          <div class="col-md-4 mb-5">
            <h5 class="fw-bold">EMAIL</h5>
            <p class="text-blue">info@Vaguthun.net</p>
          </div>
          <div class="col-md-4 mb-5">
            <img src="./assets/images/gst-reg.png" class="w-50" alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
