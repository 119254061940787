import React, { useEffect, useState } from "react";
import axios from "axios";
const ActiveTasksSection = () => {
  const [data, setData] = useState(null);
  useEffect(() => {
    axios.get("/requests/schedule/getTasks").then((res) => {
      setData(res);
    });
  }, []);

  return (
    <div class="section Tasks">
      {/* <div class="d-flex justify-content-between align-items-center mt-3">
        <div class="d-flex align-items-center gap-4">
          <p class="mb-0 ">#</p>
          <div>
            <p class="mb-0">DETAILS</p>
          </div>
        </div>
        <p class="mb-0">ACTION</p>
      </div> */}
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Number</th>
            <th scope="col">Package</th>
            <th scope="col">Method</th>
            <th scope="col">Type</th>
            <th scope="col">Amount</th>
            <th scope="col">Day</th>
            <th scope="col">Hour</th>
            <th scope="col">Repeat</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((d, i) => (
            <tr>
              <th scope="row">{i + 1}</th>
              <td>{d.number}</td>
              <td>{d.package || ""}</td>
              <td>{d.method || ""}</td>
              <td>{d.type}</td>
              <td>{d.amount}</td>
              <td>{d.day}</td>
              <td>{d.hour}</td>
              <td>{d.repeat ? "Yes" : "No"}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ActiveTasksSection;
