import React, { useState } from "react";
import axios from "axios";
import ResetPassword from "../components/ResetPassword";

const ForgotPassword = () => {
  const [sending, setSending] = useState(false);
  const [resetTab, setResetTab] = useState(false);
  const [formData, setFormData] = useState({
    mobileNumber: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSending(true);
    const res = await axios.post("/auth/forgot-password", formData);
    if (res) {
      setResetTab(true);
    }
    setSending(false);
  };

  return (
    <div className="main_login">
      <div class="container-login container">
        <div class="text">Reset Password</div>
        {resetTab ? (
          <ResetPassword mobileNumber={formData.mobileNumber} />
        ) : (
          <form onSubmit={handleSubmit}>
            <div class="form-row">
              <div class="input-data">
                <input
                  type="number"
                  name="mobileNumber"
                  value={formData.mobileNumber}
                  onChange={handleChange}
                  required
                  min={0}
                />
                <div class="underline"></div>
                <label for="">Mobile Number</label>
              </div>
            </div>

            <button
              type="submit"
              disabled={sending}
              class="btn text-light bg-blue rounded-0 ms-4 px-4 py-2 my-3"
            >
              {sending ? "Sending..." : "Send OTP"}
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
