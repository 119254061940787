import React, { useState } from "react";
import Sidebar from "../components/Sidebar";
import Dropdown from "../components/Dropdown";
import axios from "axios";
import { HiOutlineBars4 } from "react-icons/hi2";

const GiftCards = () => {
  const products = [
    {
      id: 1,
      name: "USDT 2 (BEP20)",
      price: 45,
      description: "You will be able to send it to Any BEP20 Address",
      type: "USDT (BEP20)",
    },
  ];

  const createRequest = () => {
    const { name, price } = products[0];
    axios.post("/requests/giftcard", { name, price }).then((res) => {});
  };

  //////////////////////////
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <div class="d-flex bg-light">
        {isOpen && <Sidebar />}

        <div class="rightSide">
          <div class="bg-Banner">
            <div class="d-flex justify-content-between mx-5 align-items-center py-3">
              <div className="d-flex align-items-center gap-2">
                <HiOutlineBars4
                  size={23}
                  onClick={toggleSidebar}
                  className="text-light cursor-pointer"
                />
                <h6 class="text-light mb-0">VAGUTHUN RECHARGE</h6>
              </div>{" "}
              <Dropdown />
            </div>
            <div class="mt-5">
              <h1 class="fw-bold text-center text-light">Gift Cards</h1>
              <p class="fs-5 text-center text-light">
                Buy Gift Cards instantly
              </p>
              <p class="bg-danger col-md-3 text-center text-light rounded mx-auto">
                GIFT CARDS ARE NON REFUNDABLE
              </p>
            </div>
          </div>

          <div class="mx-3 my-4 p-3">
            <div class="row">
              {products.map((product, index) => (
                <div key={index} className="col-md-3">
                  <div className="bg-white shadow p-2 py-4">
                    <p className="text-center">{product.name}</p>
                    <h4 className="bg-light text-center py-3">
                      {product.price} MVR
                    </h4>
                    <p className="fs-12 text-center">{product.description}</p>
                    <p className="bg-danger rounded text-light mx-auto fs-13 col-md-6 text-center mx-auto">
                      {product.type}
                    </p>
                    <button
                      onClick={createRequest}
                      className="btn bg-blue text-light rounded-pill mt-4 py-2 px-4 d-flex mx-auto"
                    >
                      Buy Now
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GiftCards;
