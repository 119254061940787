import React, { useState } from "react";
import axios from "axios";

const OoredooSection = () => {
  const [data, setData] = useState({
    number: "",
    package: "Dhiraagu Daily unlimited (107MVR)",
    day: "1",
    hour: "00:00",
    repeat: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const sendRequest = () => {
    axios.post("/requests/schedule/package", data).then((res) => {
      if (res) {
        setData({
          number: "",
          package: "",
          day: "1",
          hour: "00:00",
          repeat: false,
        });
      }
    });
  };
  return (
    <div class="section Ooredoo">
      <h5>Ooredoo Packages</h5>
      <hr />
      <div class="row">
        <div class="col-md-4">
          <p class="mb-0 fs-14">Schedule any Ooredoo package of your choice.</p>
        </div>
        <div class="col-md-8">
          <label>Mobile Number</label>
          <div class="input-group mb-3">
            <span class="input-group-text" id="basic-addon1">
              <i class="fa-solid fa-phone"></i>
            </span>
            <input
              type="text"
              class="form-control"
              placeholder="Mobile Number"
              value={data.number}
              onChange={handleChange}
              name="number"
            />
          </div>
          <div>
            <label>Package</label>
            <div class="input-group mb-3">
              <span class="input-group-text" id="basic-addon1">
                <i class="fa-solid fa-phone"></i>
              </span>
              <select
                value={data.package}
                onChange={handleChange}
                name="package"
                class="form-control"
              >
                <option value="Dhiraagu Daily unlimited (107MVR)">
                  Dhiraagu Daily unlimited (107MVR)
                </option>
              </select>
            </div>
          </div>
          <label>Day</label>
          <div class="input-group mb-3">
            <span class="input-group-text" id="basic-addon1">
              <i class="fa-regular fa-calendar-days"></i>
            </span>
            <select class="form-control">
              {Array(31)
                .fill()
                .map((_, i) => (
                  <option value={i + 1}>{i + 1}</option>
                ))}
              <option value="Everyday">Everyday</option>
              <option value="Every Sunday">Every Sunday</option>
              <option value="Every Monday">Every Monday</option>
              <option value="Every Tuesday">Every Tuesday</option>
              <option value="Every Wednesday">Every Wednesday</option>
              <option value="Every Thursday">Every Thursday</option>
              <option value="Every Friday">Every Friday</option>
              <option value="Every Saturday">Every Saturday</option>
            </select>
          </div>
          <label>Hour</label>
          <div class="input-group mb-3">
            <span class="input-group-text" id="basic-addon1">
              <i class="fa-regular fa-clock"></i>
            </span>
            <select
              value={data.hour}
              onChange={handleChange}
              name="hour"
              class="form-control"
            >
              {Array(24)
                .fill()
                .map((_, i) => (
                  <option value={String("0" + i).slice(-2) + ":00"}>
                    {String("0" + i).slice(-2)}:00
                  </option>
                ))}
            </select>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              defaultChecked={data.repeat}
              onChange={(e) => setData({ ...data, repeat: e.target.checked })}
              name="repeat"
              id="flexCheckDefault"
            />
            <label class="form-check-label" for="flexCheckDefault">
              Repeat Task
            </label>
          </div>
          <button onClick={sendRequest} class="btn bg-primary text-light">
            Create
          </button>
        </div>
      </div>
    </div>
  );
};

export default OoredooSection;
