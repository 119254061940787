// Dhiraagu Daily Unlimited (107 MVR)
// Dhiraagu Weekly Unlimited (702 MVR)
// 100GB (3 Days) (108 MVR)
// 100GB (5 Days) (160 MVR)
// 100GB (7 Days) (244 MVR)
// 100GB (30 Days) (810 MVR)
// 100GB (15 Days) (432 MVR)
// 100GB (21 Days) (594 MVR)
// Salhi 2 Weeks 2 GB Daily (286 MVR)
// Salhi 28 Days ( 2GB + 2GB Promo ) (642 MVR)
// Salhi Monthly x2 ( 5GB Daily Promo) (804 MVR)
// Salhi Quarterly (90 days/ 2GB Daily) (1188 MVR)
// Salhi 180 (4GB Daily) (2915 MVR)
// Salhi Anual (1GB Daily) (3239 MVR)
// Salhi Quarterly x2 (3GB Daily) (1615 MVR)
// Salhi Weekly 2GB Daily (156 MVR)
// Three Weeks (25 GB) (324 MVR)
// Three Weeks (15GB) (297 MVR)
// convert these to array of objects including name and price and inlucde unique id in them
const packages = [
  {
    id: 1,
    name: "Dhiraagu Daily unlimited",
    price: 107,
  },
  {
    id: 2,
    name: "Dhiraagu Weekly unlimited",
    price: 702,
  },
  {
    id: 3,
    name: "100GB (3 Days)",
    price: 108,
  },
  {
    id: 4,
    name: "100GB (5 Days)",
    price: 160,
  },
  {
    id: 5,
    name: "100GB (7 Days)",
    price: 244,
  },
  {
    id: 6,
    name: "100GB (30 Days)",
    price: 810,
  },
  {
    id: 7,
    name: "100GB (15 Days)",
    price: 432,
  },
  {
    id: 8,
    name: "100GB (21 Days)",
    price: 594,
  },
  {
    id: 9,
    name: "Salhi 2 Weeks 2 GB Daily",
    price: 286,
  },
  {
    id: 10,
    name: "Salhi 28 Days ( 2GB + 2GB Promo )",
    price: 642,
  },
  {
    id: 11,
    name: "Salhi Monthly x2 ( 5GB Daily Promo)",
    price: 804,
  },
  {
    id: 12,
    name: "Salhi Quarterly (90 days/ 2GB Daily)",
    price: 1188,
  },
  {
    id: 13,
    name: "Salhi 180 (4GB Daily)",
    price: 2915,
  },
  {
    id: 14,
    name: "Salhi Anual (1GB Daily)",
    price: 3239,
  },
  {
    id: 15,
    name: "Salhi Quarterly x2 (3GB Daily)",
    price: 1615,
  },
  {
    id: 16,
    name: "Salhi Weekly 2GB Daily",
    price: 156,
  },
  {
    id: 17,
    name: "Three Weeks (25 GB)",
    price: 324,
  },
  {
    id: 18,
    name: "Three Weeks (15GB)",
    price: 297,
  },
];

const ooredooPackages = [
  {
    id: 1,
    name: "Oorodoo Data 200 (2.5 GB)",
    price: 216,
  },
  {
    id: 2,
    name: "Oorodoo Data 350 (7 GB)",
    price: 378,
  },
  {
    id: 3,
    name: "Oorodoo Data 500 (12 GB)",
    price: 540,
  },
  {
    id: 4,
    name: "Oorodoo Data 880 (17 GB)",
    price: 950,
  },
  {
    id: 5,
    name: "Oorodoo Data 1100 (40 GB)",
    price: 1188,
  },
  {
    id: 6,
    name: "Digital Combo 150 (3 GB)",
    price: 162,
  },
  {
    id: 7,
    name: "MAGIC DATA 202",
    price: 218,
  },
  {
    id: 8,
    name: "Faseyha Home Broadband 50GB",
    price: 754,
  },
  {
    id: 9,
    name: "Faseyha Home Broadband 120GB",
    price: 1186,
  },
  {
    id: 10,
    name: "Faseyha Home Broadband 180GB",
    price: 1672,
  },
  {
    id: 11,
    name: "Faseyha Home Booster (AddOn)",
    price: 108,
  },
  {
    id: 12,
    name: "Ooredoo Prepaid Broadband 299 (5GB)",
    price: 322,
  },
  {
    id: 13,
    name: "Ooredoo Prepaid Broadband 499 (10GB)",
    price: 538,
  },
  {
    id: 14,
    name: "Ooredoo Prepaid Broadband 699 (25GB)",
    price: 754,
  },
  {
    id: 15,
    name: "Ooredoo Prepaid Broadband 899 (30GB)",
    price: 970,
  },
  {
    id: 16,
    name: "Ooredoo Prepaid Broadband 1099 (50GB)",
    price: 1186,
  },
  {
    id: 17,
    name: "Ooredoo Prepaid Broadband 2999 (150GB)",
    price: 3238,
  },
  {
    id: 18,
    name: "Ooredoo Prepaid Broadband 5699 (300GB)",
    price: 6154,
  },
];

const getPackageById = (id, isDhiraagu) => {
  if (isDhiraagu) {
    return packages.find((p) => p.id == id);
  } else {
    return ooredooPackages.find((p) => p.id == id);
  }
};

export { packages, getPackageById, ooredooPackages };
